
import { connectionCurvePoints, connectorRectPoints, rectContainer, rectPadded } from '@icepanel/app-graphics'
import { CatalogTechnology, DiagramConnection, DiagramObject, FlowStep, Landscape, ModelConnectionRequired, ModelObjectIconNullable, ModelObjectRequired, ModelObjectTechnology, OrganizationBillingCurrency, OrganizationBillingCycle } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import firestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import Status from '@/helpers/status'
import { AlertModule } from '@/modules/alert/store'
import { CatalogModule } from '@/modules/catalog/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import LandscapeSetupForm from '@/modules/landscape/components/setup/form/form.vue'
import LandscapeSetupPreview from '@/modules/landscape/components/setup/preview/preview.vue'
import * as analytics from '@/modules/landscape/helpers/analytics'
import { LandscapeSetupData, LandscapeSetupFormStep, LandscapeSetupFormStepOption, onboardingSteps } from '@/modules/landscape/helpers/setup-form'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import * as organizationAnalytics from '@/modules/organization/helpers/analytics'
import { OrganizationModule } from '@/modules/organization/store'
import { UserModule } from '@/modules/user/store'

@Component({
  components: {
    LandscapeSetupForm,
    LandscapeSetupPreview
  },
  name: 'LandscapeSetup'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  catalogModule = getModule(CatalogModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  creatingLandscape = false
  currentStepIndex = 0
  onboardingSteps = onboardingSteps
  data: LandscapeSetupData = {}

  rootModelId = firestoreId('model-object')
  systemModelId = firestoreId('model-object')
  actorModelId = firestoreId('model-object')
  appModelId = firestoreId('model-object')
  apiModelId = firestoreId('model-object')
  storeModelId = firestoreId('model-object')

  systemId = randomId()
  systemAreaId = randomId()
  actorId = randomId()
  appId = randomId()
  apiId = randomId()
  storeId = randomId()

  actorToAppModelId = firestoreId('model-connection')
  appToApiModelId = firestoreId('model-connection')
  apiToStoreModelId = firestoreId('model-connection')

  actorToAppId = randomId()
  appToApiId = randomId()
  apiToStoreId = randomId()

  flowStepIds = [randomId(), randomId(), randomId(), randomId(), randomId()]

  modelObjects: Record<string, ModelObjectRequired> = {}
  modelConnections: Record<string, ModelConnectionRequired> = {}
  diagramObjects: Record<string, DiagramObject> = {}
  diagramConnections: Record<string, DiagramConnection> = {}
  flowSteps: Record<string, FlowStep> = {}

  get currentStep (): LandscapeSetupFormStep {
    return onboardingSteps[this.currentStepIndex]
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.$queryValue('organization') || this.currentLandscape?.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get billingCycle () {
    return this.$queryValue('billing_cycle') as OrganizationBillingCycle | null
  }

  get billingCurrency () {
    return this.$queryValue('billing_currency') as OrganizationBillingCurrency | null
  }

  get resourceLoaded () {
    return this.editorModule.resourceLoaded
  }

  get focusCameraEnabled () {
    return this.currentStepIndex !== 9
  }

  get selectedObjectIds () {
    switch (this.currentStepIndex) {
      case 0: return [this.actorId]
      case 1: return [this.appId]
      case 2: return [this.appId]
      case 4: return [this.apiId]
      case 5: return [this.apiId]
      case 6: return [this.storeId]
      case 7: return [this.storeId]
      default: return []
    }
  }

  get selectedFlowStepIds () {
    switch (this.currentStepIndex) {
      case 3: return [this.flowStepIds[0]]
      case 8: return [this.flowStepIds[4]]
      case 9: return [this.flowStepIds[0]]
      default: return []
    }
  }

  created () {
    const systemModel: ModelObjectRequired = {
      description: 'A brief description of your system',
      name: 'Your system',
      parentId: this.rootModelId,
      type: 'system'
    }
    const actorModel: ModelObjectRequired = {
      caption: 'Someone who uses your solution',
      name: 'Person',
      parentId: this.rootModelId,
      type: 'actor'
    }
    const appModel: ModelObjectRequired = {
      caption: 'Frontend user interface',
      name: 'Frontend',
      parentId: this.systemModelId,
      type: 'app'
    }
    const apiModel: ModelObjectRequired = {
      caption: 'Backend REST API',
      name: 'API',
      parentId: this.systemModelId,
      type: 'app'
    }
    const storeModel: ModelObjectRequired = {
      caption: 'Data storage',
      name: 'Store',
      parentId: this.systemModelId,
      type: 'store'
    }

    Vue.set(this.modelObjects, this.systemModelId, systemModel)
    Vue.set(this.modelObjects, this.actorModelId, actorModel)
    Vue.set(this.modelObjects, this.appModelId, appModel)
    Vue.set(this.modelObjects, this.apiModelId, apiModel)
    Vue.set(this.modelObjects, this.storeModelId, storeModel)

    const actor: DiagramObject = {
      height: 158,
      id: this.actorId,
      modelId: this.actorModelId,
      shape: 'box',
      type: 'actor',
      width: 256,
      x: 304,
      y: 0
    }
    const app: DiagramObject = {
      height: 128,
      id: this.appId,
      modelId: this.appModelId,
      shape: 'box',
      type: 'app',
      width: 256,
      x: 304,
      y: 296
    }
    const api: DiagramObject = {
      height: 128,
      id: this.apiId,
      modelId: this.apiModelId,
      shape: 'box',
      type: 'app',
      width: 256,
      x: 304,
      y: 552
    }
    const store: DiagramObject = {
      height: 128,
      id: this.storeId,
      modelId: this.storeModelId,
      shape: 'box',
      type: 'store',
      width: 256,
      x: 304,
      y: 808
    }

    const actorToAppModel: ModelConnectionRequired = {
      direction: 'outgoing',
      name: 'Uses',
      originId: this.actorModelId,
      targetId: this.appModelId
    }
    const appToApiModel: ModelConnectionRequired = {
      direction: 'outgoing',
      name: 'Request endpoint',
      originId: this.appModelId,
      targetId: this.apiModelId
    }
    const apiToStoreModel: ModelConnectionRequired = {
      direction: 'outgoing',
      name: 'Store data',
      originId: this.apiModelId,
      targetId: this.storeModelId
    }

    Vue.set(this.modelConnections, this.actorToAppModelId, actorToAppModel)
    Vue.set(this.modelConnections, this.appToApiModelId, appToApiModel)
    Vue.set(this.modelConnections, this.apiToStoreModelId, apiToStoreModel)

    Vue.set(this.diagramObjects, actor.id, actor)
    Vue.set(this.diagramObjects, app.id, app)
    Vue.set(this.diagramObjects, api.id, api)
    Vue.set(this.diagramObjects, store.id, store)

    const systemChildren = Object.values(this.diagramObjects).filter(o => this.modelObjects[o.modelId]?.parentId === this.systemModelId)
    const systemRect = systemChildren.reduce((p, c) => rectContainer(p, c), { height: systemChildren[0].height, width: systemChildren[0].width, x: systemChildren[0].x, y: systemChildren[0].y })
    const systemRectPadded = rectPadded(systemRect, { bottom: 64, left: 48, right: 48, top: 64 })
    const systemArea: DiagramObject = {
      ...systemRectPadded,
      id: this.systemAreaId,
      modelId: this.systemModelId,
      shape: 'area',
      type: 'system'
    }

    Vue.set(this.diagramObjects, systemArea.id, systemArea)

    const actorToApp: DiagramConnection = {
      id: this.actorToAppId,
      labelPosition: 50,
      lineShape: this.currentOrganization?.lineShapeDefault ?? 'curved',
      modelId: this.actorToAppModelId,
      originConnector: 'bottom-center',
      originId: this.actorId,
      points: connectionCurvePoints(
        connectorRectPoints(this.diagramObjects[this.actorId])['bottom-center'] ?? { x: 0, y: 0 },
        'bottom-center',
        connectorRectPoints(this.diagramObjects[this.appId])['top-center'] ?? { x: 0, y: 0 },
        'top-center'
      ),
      targetConnector: 'top-center',
      targetId: this.appId
    }
    const appToApi: DiagramConnection = {
      id: this.appToApiId,
      labelPosition: 50,
      lineShape: this.currentOrganization?.lineShapeDefault ?? 'curved',
      modelId: this.appToApiModelId,
      originConnector: 'bottom-center',
      originId: this.appId,
      points: connectionCurvePoints(
        connectorRectPoints(this.diagramObjects[this.appId])['bottom-center'] ?? { x: 0, y: 0 },
        'bottom-center',
        connectorRectPoints(this.diagramObjects[this.apiId])['top-center'] ?? { x: 0, y: 0 },
        'top-center'
      ),
      targetConnector: 'top-center',
      targetId: this.apiId
    }
    const apiToStore: DiagramConnection = {
      id: this.apiToStoreId,
      labelPosition: 50,
      lineShape: this.currentOrganization?.lineShapeDefault ?? 'curved',
      modelId: this.apiToStoreModelId,
      originConnector: 'bottom-center',
      originId: this.apiId,
      points: connectionCurvePoints(
        connectorRectPoints(this.diagramObjects[this.apiId])['bottom-center'] ?? { x: 0, y: 0 },
        'bottom-center',
        connectorRectPoints(this.diagramObjects[this.storeId])['top-center'] ?? { x: 0, y: 0 },
        'top-center'
      ),
      targetConnector: 'top-center',
      targetId: this.storeId
    }

    Vue.set(this.diagramConnections, actorToApp.id, actorToApp)
    Vue.set(this.diagramConnections, appToApi.id, appToApi)
    Vue.set(this.diagramConnections, apiToStore.id, apiToStore)

    const flowStep1: FlowStep = {
      description: '',
      flowId: '',
      id: this.flowStepIds[0],
      index: 0,
      originId: this.actorId,
      pathId: null,
      pathIndex: null,
      targetId: this.appId,
      type: 'outgoing',
      viaId: this.actorToAppId
    }
    const flowStep2: FlowStep = {
      description: 'Request to API',
      flowId: '',
      id: this.flowStepIds[1],
      index: 1,
      originId: this.appId,
      pathId: null,
      pathIndex: null,
      targetId: this.apiId,
      type: 'outgoing',
      viaId: this.appToApiId
    }
    const flowStep3: FlowStep = {
      description: 'Store data in cache',
      flowId: '',
      id: this.flowStepIds[2],
      index: 2,
      originId: this.apiId,
      pathId: null,
      pathIndex: null,
      targetId: this.storeId,
      type: 'outgoing',
      viaId: this.apiToStoreId
    }
    const flowStep4: FlowStep = {
      description: 'Respond with result',
      flowId: '',
      id: this.flowStepIds[3],
      index: 3,
      originId: this.apiId,
      pathId: null,
      pathIndex: null,
      targetId: this.appId,
      type: 'reply',
      viaId: this.appToApiId
    }
    const flowStep5: FlowStep = {
      description: '',
      flowId: '',
      id: this.flowStepIds[4],
      index: 4,
      originId: this.appId,
      pathId: null,
      pathIndex: null,
      targetId: this.actorId,
      type: 'reply',
      viaId: this.actorToAppId
    }

    Vue.set(this.flowSteps, flowStep1.id, flowStep1)
    Vue.set(this.flowSteps, flowStep2.id, flowStep2)
    Vue.set(this.flowSteps, flowStep3.id, flowStep3)
    Vue.set(this.flowSteps, flowStep4.id, flowStep4)
    Vue.set(this.flowSteps, flowStep5.id, flowStep5)
  }

  mounted () {
    this.catalogModule.catalogTechnologiesList({})

    if (!this.editorModule.resourceLoaded) {
      this.editorModule.loadResources()
    }

    analytics.landscapeSetupScreen.track(this, {
      organizationId: this.currentOrganization ? [this.currentOrganization.id] : []
    })
  }

  optionSelected (option: LandscapeSetupFormStepOption) {
    switch (this.currentStepIndex) {
      case 0: {
        Vue.set(this.data, 'actor', option)
        Vue.set(this.modelObjects[this.actorModelId], 'name', this.data.actor?.name || 'Actor')
        break
      }
      case 1: {
        Vue.set(this.data, 'app', option)
        Vue.set(this.modelObjects[this.appModelId], 'name', this.data.app?.name || 'App')
        Vue.set(this.modelObjects[this.appModelId], 'icon', this.technologyListToDefaultIcon(this.data.app?.technologies))
        Vue.set(this.modelObjects[this.appModelId], 'technologies', this.technologyListToRecord(this.data.app?.technologies))
        break
      }
      case 3: {
        Vue.set(this.data, 'appConnectionName', option.name)
        Vue.set(this.flowSteps[this.flowStepIds[0]], 'description', this.data.appConnectionName || 'Uses')
        break
      }
      case 6: {
        Vue.set(this.data, 'store', option)
        Vue.set(this.modelObjects[this.storeModelId], 'name', this.data.store?.name || 'Store')
        Vue.set(this.modelObjects[this.storeModelId], 'icon', this.technologyListToDefaultIcon(this.data.store?.technologies))
        Vue.set(this.modelObjects[this.storeModelId], 'technologies', this.technologyListToRecord(this.data.store?.technologies))
        break
      }
      case 8: {
        Vue.set(this.data, 'apiReturn', option.name)
        Vue.set(this.flowSteps[this.flowStepIds[4]], 'description', this.data.apiReturn || 'Return')
        break
      }
      default:
    }
  }

  technologySelected (technologies: CatalogTechnology[]) {
    switch (this.currentStepIndex) {
      case 2: {
        if (this.data.app) {
          Vue.set(this.data.app, 'technologies', technologies)
          Vue.set(this.modelObjects[this.appModelId], 'icon', this.technologyListToDefaultIcon(this.data.app?.technologies))
          Vue.set(this.modelObjects[this.appModelId], 'technologies', this.technologyListToRecord(this.data.app?.technologies))
        }
        break
      }
      case 5: {
        if (this.data.api) {
          Vue.set(this.data.api, 'technologies', technologies)
          Vue.set(this.modelObjects[this.apiModelId], 'icon', this.technologyListToDefaultIcon(this.data.api?.technologies))
          Vue.set(this.modelObjects[this.apiModelId], 'technologies', this.technologyListToRecord(this.data.api?.technologies))
        }
        break
      }
      case 7: {
        if (this.data.store) {
          Vue.set(this.data.store, 'technologies', technologies)
          Vue.set(this.modelObjects[this.storeModelId], 'icon', this.technologyListToDefaultIcon(this.data.store?.technologies))
          Vue.set(this.modelObjects[this.storeModelId], 'technologies', this.technologyListToRecord(this.data.store?.technologies))
        }
        break
      }
    }
  }

  textSelected (text: string) {
    switch (this.currentStepIndex) {
      case 4: {
        Vue.set(this.data, 'api', { name: text || 'API' })
        Vue.set(this.modelObjects[this.apiModelId], 'name', this.data.api?.name || 'API')
        Vue.set(this.modelObjects[this.apiModelId], 'icon', this.technologyListToDefaultIcon(this.data.api?.technologies))
        Vue.set(this.modelObjects[this.apiModelId], 'technologies', this.technologyListToRecord(this.data.api?.technologies))
        break
      }
    }
  }

  ctaClicked () {
    if (this.currentStepIndex === onboardingSteps.length - 1) {
      this.createLandscape()
    }
  }

  trackStepEvent () {
    let choice: string[] = []

    switch (this.currentStepIndex) {
      case 0: {
        choice = [this.data.actor?.name || 'Actor']
        break
      }
      case 1: {
        choice = [this.data.app?.name || 'App']
        break
      }
      case 2: {
        choice = this.data.app?.technologies?.map(o => o.name) ?? []
        break
      }
      case 3: {
        choice = [this.data.appConnectionName || 'Uses']
        break
      }
      case 4: {
        choice = [this.data.api?.name || 'API']
        break
      }
      case 5: {
        choice = this.data.api?.technologies?.map(o => o.name) ?? []
        break
      }
      case 6: {
        choice = [this.data.store?.name || 'Store']
        break
      }
      case 7: {
        choice = this.data.store?.technologies?.map(o => o.name) ?? []
        break
      }
      case 8: {
        choice = [this.data.apiReturn || 'Return']
        break
      }
    }

    analytics.landscapeSetupStepComplete.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : [],
      landscapeSetupChoice: choice,
      landscapeSetupStep: this.currentStepIndex + 1,
      landscapeSetupVersion: 1,
      organizationId: this.currentOrganization ? [this.currentOrganization.id] : []
    })
  }

  async createLandscape () {
    if (!this.userModule.user) {
      return
    }

    this.creatingLandscape = true
    let organization = this.currentOrganization
    let landscape: Landscape | undefined

    try {
      if (!organization) {
        organization = await this.organizationModule.organizationCreate({
          billingCurrency: this.billingCurrency ?? undefined,
          billingCycle: this.billingCycle ?? undefined,
          billingEmail: this.userModule.user.email,
          name: `${this.userModule.user.name}'s organization`
        })

        organizationAnalytics.organizationCreate.track(this, {
          organizationBillingCurrency: organization.billingCurrency,
          organizationBillingCycle: organization.billingCycle,
          organizationBillingEmail: organization.billingEmail,
          organizationId: [organization.id],
          organizationName: organization.name,
          organizationPlan: organization.plan,
          organizationSeats: organization.seats,
          organizationStatus: organization.status
        })

        try {
          await this.organizationModule.organizationBillingSubscriptionCreate({
            organizationId: organization.id,
            subscription: {
              billingCurrency: this.billingCurrency ?? undefined,
              billingCycle: this.billingCycle ?? undefined,
              trial: true
            }
          })

          organizationAnalytics.organizationBillingSubscriptionCreate.track(this, {
            organizationBillingCurrency: organization.billingCurrency,
            organizationBillingCycle: organization.billingCycle,
            organizationId: [organization.id],
            organizationPlan: 'free',
            organizationSeats: 5,
            organizationTrial: true
          })
        } catch (err: any) {
          this.alertModule.pushAlert({
            color: 'error',
            message: err.body?.message ?? err.message
          })
        }
      }

      const res = await this.landscapeModule.landscapeCreate({
        name: `${this.userModule.user.name}'s landscape`,
        organizationId: organization.id
      })
      landscape = res.landscape
      const version = res.version

      this.landscapeModule.setLandscapesListStatus(Status.success({
        organizationId: organization.id
      }))

      analytics.landscapeCreate.track(this, {
        landscapeId: [landscape.id],
        landscapeName: landscape.name,
        organizationId: [landscape.organizationId]
      })

      const [modelObjectRoot] = await this.modelModule.objectsList({
        filter: {
          type: 'root'
        },
        landscapeId: landscape.id,
        versionId: version.id
      })
      if (!modelObjectRoot) {
        throw new Error('Could not find root model object')
      }

      const system = this.modelObjects[this.systemModelId]
      const appDiagramId = firestoreId('diagram')

      const l1 = Object.entries(this.modelObjects).filter(([, o]) => o.type === 'system' || o.type === 'actor' || o.type === 'group')
      const l2 = Object.entries(this.modelObjects).filter(([, o]) => o.type === 'app' || o.type === 'store')

      await Promise.all(l1.map(([id, o]) => this.modelModule.objectUpsert({
        landscapeId: landscape!.id,
        objectId: id,
        props: {
          ...o,
          parentId: modelObjectRoot.id
        },
        versionId: version.id
      })))
      await Promise.all(l2.map(([id, o]) => this.modelModule.objectUpsert({
        landscapeId: landscape!.id,
        objectId: id,
        props: o,
        versionId: version.id
      })))
      await Promise.all(Object.entries(this.modelConnections).map(([id, o]) => this.modelModule.connectionUpsert({
        connectionId: id,
        landscapeId: landscape!.id,
        props: o,
        versionId: version.id
      })))

      const contextActor: DiagramObject = {
        height: 158,
        id: randomId(),
        modelId: this.actorModelId,
        shape: 'box',
        type: 'actor',
        width: 256,
        x: 0,
        y: 0
      }
      const contextApp: DiagramObject = {
        height: 128,
        id: randomId(),
        modelId: this.systemModelId,
        shape: 'box',
        type: 'system',
        width: 256,
        x: 0,
        y: 320
      }
      const contextActorToSystem: DiagramConnection = {
        id: randomId(),
        labelPosition: 50,
        lineShape: this.currentOrganization?.lineShapeDefault ?? 'curved',
        modelId: this.actorToAppModelId,
        originConnector: 'bottom-center',
        originId: contextActor.id,
        points: connectionCurvePoints(
          connectorRectPoints(contextActor)['bottom-center'] ?? { x: 0, y: 0 },
          'bottom-center',
          connectorRectPoints(contextApp)['top-center'] ?? { x: 0, y: 0 },
          'top-center'
        ),
        targetConnector: 'top-center',
        targetId: contextApp.id
      }

      const [, appDiagram] = await Promise.all([
        this.diagramModule.diagramCreate({
          landscapeId: landscape.id,
          props: {
            connections: {
              [contextActorToSystem.id]: contextActorToSystem
            },
            index: 0,
            modelId: modelObjectRoot.id,
            name: 'Context Diagram',
            objects: {
              [contextActor.id]: contextActor,
              [contextApp.id]: contextApp
            },
            type: 'context-diagram'
          },
          updateViewedAt: true,
          versionId: version.id
        }),
        this.diagramModule.diagramUpsert({
          diagramId: appDiagramId,
          landscapeId: landscape.id,
          props: {
            connections: this.diagramConnections,
            index: 0,
            modelId: this.systemModelId,
            name: `${system.name} App Diagram`,
            objects: this.diagramObjects,
            type: 'app-diagram'
          },
          updateViewedAt: true,
          versionId: version.id
        })
      ])

      const [flow] = await Promise.all([
        this.flowModule.flowCreate({
          landscapeId: landscape.id,
          props: {
            diagramId: appDiagramId,
            name: `${this.data.actor?.name ?? 'User'} flow`,
            showAllSteps: true,
            steps: this.flowSteps
          },
          versionId: version.id
        }),
        this.organizationModule.organizationFind(organization.id)
      ])

      await this.$router.replace({
        name: 'diagrams',
        params: {
          landscapeId: landscape.id,
          versionId: 'latest'
        }
      })
      await this.$router.push({
        name: 'editor-diagram',
        params: {
          landscapeId: landscape.id,
          versionId: 'latest'
        },
        query: {
          diagram: appDiagram.handleId,
          flow: flow.handleId,
          flow_step: this.flowStepIds[0],
          model: system.handleId
        }
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })

      if (organization) {
        if (landscape) {
          await this.landscapeModule.landscapeDelete({
            landscapeId: landscape.id,
            organizationId: organization.id
          })
        }

        await this.organizationModule.organizationDelete(organization.id)
      }

      throw err
    } finally {
      this.creatingLandscape = false
    }
  }

  technologyListToRecord (technologies?: CatalogTechnology[]): Record<string, ModelObjectTechnology> {
    if (!technologies) { return {} }
    return technologies.reduce((map, t, i) => {
      map[t.id] = {
        color: t.color,
        iconUrl: t.iconUrl,
        iconUrlDark: t.iconUrlDark,
        iconUrlLight: t.iconUrlLight,
        id: t.id,
        index: i,
        name: t.name,
        nameShort: t.nameShort,
        provider: t.provider,
        type: t.type
      }
      return map
    }, {} as Record<string, ModelObjectTechnology>)
  }

  technologyListToDefaultIcon (technologies?: CatalogTechnology[]): ModelObjectIconNullable {
    if (!technologies || technologies.length === 0) { return null }
    return {
      catalogTechnologyId: technologies[0].id,
      name: technologies[0].name,
      url: technologies[0].iconUrl,
      urlDark: technologies[0].iconUrlDark,
      urlLight: technologies[0].iconUrlLight
    }
  }
}
