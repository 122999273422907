
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { LandscapeSetupFormStep, onboardingSteps } from '@/modules/landscape/helpers/setup-form'

@Component({
  name: 'LandscapeSetupCtaButton'
})
export default class extends Vue {
  @Prop() currentStepIndex!: number

  @Ref() readonly ctaButtonRef?: HTMLButtonElement

  onboardingSteps = onboardingSteps

  get currentStep (): LandscapeSetupFormStep {
    return onboardingSteps[this.currentStepIndex]
  }

  mounted () {
    this.ctaButtonRef?.focus()
  }
}
