
import { Context, DisplayObject, Ticker } from '@icepanel/app-canvas'
import { DiagramConnection, DiagramObject, FlowStep, ModelConnection, ModelConnectionRequired, ModelObject, ModelObjectRequired } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import getPixi from '@/helpers/pixi'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { ModelModule } from '@/modules/model/store'
import { RenderModule } from '@/modules/render/store'

interface IObject {
  model: ModelObject
  object: DiagramObject
}
interface IConnection {
  connection: DiagramConnection
  model: ModelConnection | null
}

@Component({
  name: 'LandscapeSetupPreview'
})
export default class LandscapeSetupPreview extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  renderModule = getModule(RenderModule, this.$store)

  @Ref() readonly containerRef!: HTMLElement
  @Ref() readonly canvasRef!: HTMLElement

  @Prop() readonly modelObjects?: Record<string, ModelObjectRequired>
  @Prop() readonly modelConnections?: Record<string, ModelConnectionRequired>
  @Prop() readonly diagramObjects?: Record<string, DiagramObject>
  @Prop() readonly diagramConnections?: Record<string, DiagramConnection>
  @Prop() readonly flowSteps?: Record<string, FlowStep>

  @Prop() readonly selectedObjectIds?: string[]
  @Prop() readonly selectedConnectionIds?: string[]
  @Prop() readonly selectedFlowStepIds?: string[]

  @Prop() readonly focusCameraEnabled?: boolean

  app = getPixi()
  view!: Context.Diagram.View

  get model () {
    const objects = Object
      .entries(this.modelObjects ?? {})
      .reduce((p, [id, c]) => ({
        ...p,
        [id]: this.modelModule.generateObject('', '', c, id).object
      }), {} as Record<string, ModelObject>)

    const connections = Object
      .entries(this.modelConnections ?? {})
      .reduce((p, [id, c]) => ({
        ...p,
        [id]: this.modelModule.generateConnection('', '', c, id).connection
      }), {} as Record<string, ModelConnection>)

    return {
      connections,
      objects
    }
  }

  get objects () {
    return Object
      .entries(this.diagramObjects ?? {})
      .reduce((p, [id, c]) => {
        const model = this.model.objects[c.modelId]
        if (model) {
          const object: IObject = {
            model,
            object: c
          }
          return {
            ...p,
            [id]: object
          }
        } else {
          return p
        }
      }, {} as Record<string, IObject>)
  }

  get connections () {
    return Object
      .entries(this.diagramConnections ?? {})
      .reduce((p, [id, c]) => {
        const model = c.modelId ? this.model.connections[c.modelId] ?? null : null
        const connection: IConnection = {
          connection: c,
          model
        }
        return {
          ...p,
          [id]: connection
        }
      }, {} as Record<string, IConnection>)
  }

  get diagramElements () {
    return {
      connections: this.connections,
      objects: this.objects
    }
  }

  @Watch('diagramElements')
  onDiagramElementsChanged (
    { connections, objects }: { connections: LandscapeSetupPreview['connections'], objects: LandscapeSetupPreview['objects'] },
    { connections: prevConnections, objects: prevObjects }: { connections: LandscapeSetupPreview['connections'], objects: LandscapeSetupPreview['objects'] }
  ) {
    Object
      .keys(objects)
      .filter(o => prevObjects[o] && (objects[o].object.type !== prevObjects[o].object.type || objects[o].object.shape !== prevObjects[o].object.shape))
      .forEach(o => this.removeObject(o))

    Object
      .keys(prevObjects)
      .filter(o => !objects[o])
      .map(o => {
        this.removeObject(o)
        return o
      })

    Object
      .keys(prevConnections)
      .filter(o => !connections[o])
      .map(o => {
        this.removeConnection(o)
        return o
      })

    Object
      .values(objects)
      .forEach(o => this.upsertObject(o))

    Object
      .values(connections)
      .forEach(o => this.upsertConnection(o))

    Object
      .keys(objects)
      .filter(o => prevObjects[o] && (objects[o].object.type !== prevObjects[o].object.type || objects[o].object.shape !== prevObjects[o].object.shape))
      .map(o => {
        this.initObject(o)
        return o
      })

    Object
      .keys(objects)
      .filter(o => !prevObjects[o])
      .map(id => {
        this.initObject(id)
        return id
      })

    Object
      .keys(connections)
      .filter(o => !prevConnections[o])
      .map(id => {
        this.initConnection(id)
        return id
      })

    this.focusCamera()
  }

  @Watch('selectedObjectIds')
  onSelectedObjectIdsChanged (selectedObjectIds?: string[], prevSelectedObjectIds?: string[]) {
    const selected = (selectedObjectIds ?? [])
      .filter(o => !prevSelectedObjectIds?.includes(o))
      .map(o => {
        this.view?.objects[o]?.setSelected(true)
        return o
      })

    const unselected = (prevSelectedObjectIds ?? [])
      .filter(o => !selectedObjectIds?.includes(o))
      .map(o => {
        this.view?.objects[o]?.setSelected(false)
        return o
      })

    if (selected.length || unselected.length) {
      Object.values(this.view?.objects || {}).forEach(o => o.updateStyle())
      Object.values(this.view?.connections || {}).forEach(o => o.updateStyle())

      this.focusCamera()
    }
  }

  @Watch('selectedConnectionIds')
  onCurrentConnectionIdsChanged (selectedConnectionIds?: string[], prevCurrentConnectionIds?: string[]) {
    const selected = (selectedConnectionIds ?? [])
      .filter(o => !prevCurrentConnectionIds?.includes(o))
      .map(o => {
        this.view?.connections[o]?.setSelected(true)
        return o
      })

    const unselected = (prevCurrentConnectionIds ?? [])
      .filter(o => !selectedConnectionIds?.includes(o))
      .map(o => {
        this.view?.connections[o]?.setSelected(false)
        return o
      })

    if (selected.length || unselected.length) {
      Object.values(this.view?.objects || {}).forEach(o => o.updateStyle())
      Object.values(this.view?.connections || {}).forEach(o => o.updateStyle())

      this.focusCamera()
    }
  }

  @Watch('selectedFlowStepIds')
  onSelectedFlowStepIdsChanged (selectedFlowStepIds: string[]) {
    const selectedFlowSteps = Object.values(this.flowSteps ?? {}).filter(o => selectedFlowStepIds?.includes(o.id))
    this.view.flow.setStore(selectedFlowSteps)

    this.focusCamera()
  }

  @Watch('flowSteps', { deep: true })
  onFlowStepsChanged (flowSteps: Record<string, FlowStep>) {
    const { flow } = this.flowModule.generateFlow('', '', {
      diagramId: '',
      name: '',
      showAllSteps: true,
      steps: flowSteps ?? {}
    })
    this.view.flow.setModel(flowSteps ? flow : null, true)
  }

  @Watch('focusCameraEnabled')
  onFocusCameraEnabledChanged () {
    this.focusCamera()
  }

  mounted () {
    this.app.resizeTo = this.containerRef

    this.view = new Context.Diagram.View(this.app, {
      comments: {},
      commit: 0,
      connections: {},
      createdAt: '',
      createdBy: 'user',
      createdById: '',
      groupId: '',
      handleId: '',
      id: '',
      landscapeId: '',
      modelId: '',
      name: '',
      objects: {},
      status: 'current',
      tasksProposed: [],
      type: 'context-diagram',
      updatedAt: '',
      updatedBy: 'user',
      updatedById: '',
      version: 0,
      versionId: ''
    }, {
      focusIds: [],
      hideIds: [],
      iconUrl: env.ICON_URL,
      permission: 'read'
    }, {
      getModelConnections: () => this.model.connections,
      getModelObjects: () => this.model.objects
    } as any)

    this.canvasRef.onselectstart = () => false

    // tabbing will create a div that is more than the height of the page
    this.app.renderer.plugins.accessibility.destroy()

    this.app.view.setAttribute('tabIndex', '-1')

    if (window.Cypress) {
      window.view = this.view
    }

    Ticker.shared.start()

    this.canvasRef.appendChild(this.app.view)
    this.resize()

    Object.values(this.objects).forEach(o => this.upsertObject(o))
    Object.values(this.connections).forEach(o => this.upsertConnection(o))

    Object.values(this.objects).forEach(o => this.initObject(o.object.id))
    Object.values(this.connections).forEach(o => this.initConnection(o.connection.id))

    Object.values(this.view.objects).forEach(o => o.setSelected(this.selectedObjectIds?.includes(o.id) ?? false))
    Object.values(this.view.connections).forEach(o => o.setSelected(this.selectedConnectionIds?.includes(o.id) ?? false))

    const { flow } = this.flowModule.generateFlow('', '', {
      diagramId: '',
      name: '',
      showAllSteps: true,
      steps: this.flowSteps ?? {}
    })
    this.view.flow.setModel(this.flowSteps ? flow : null, true)

    const selectedFlowSteps = Object.values(this.flowSteps ?? {}).filter(o => this.selectedFlowStepIds?.includes(o.id))
    this.view.flow.setStore(selectedFlowSteps)

    this.focusCamera(false)
  }

  resize () {
    if (this.app && this.canvasRef.childElementCount) {
      this.app.renderer.resize(this.containerRef.clientWidth, this.containerRef.clientHeight)
      this.app.render()

      this.$nextTick(() => {
        this.focusCamera(false)
      })
    }
  }

  beforeDestroy () {
    this.canvasRef.removeChild(this.app.view)
  }

  destroyed () {
    Ticker.shared.stop()

    Object.keys(this.view?.connections ?? {}).forEach(o => this.removeConnection(o))
    Object.keys(this.view?.objects ?? {}).forEach(o => this.removeObject(o))

    this.view?.destroy()
  }

  focusCamera (animate = true) {
    const ids = [
      ...this.selectedConnectionIds ?? [],
      ...this.selectedObjectIds ?? [],
      ...this.selectedFlowStepIds?.flatMap(o => [this.flowSteps?.[o].originId, this.flowSteps?.[o].targetId, this.flowSteps?.[o].viaId]) ?? []
    ].filter((o): o is string => !!o)

    if (this.focusCameraEnabled && ids.length) {
      this.view.transitionState(new Context.Diagram.State.Teleport({
        connections: Object
          .values(this.connections)
          .filter(o => ids.includes(o.connection.id))
          .map(o => o.connection),
        objects: Object
          .values(this.objects)
          .filter(o => ids.includes(o.object.id))
          .map(o => o.object)
      }, {
        animate
      }))
    } else {
      this.view.transitionState(new Context.Diagram.State.Teleport({
        connections: Object
          .values(this.connections)
          .map(o => o.connection),
        objects: Object
          .values(this.objects)
          .map(o => o.object)
      }, {
        animate
      }))
    }
  }

  upsertObject ({ object, model }: LandscapeSetupPreview['objects'][0]) {
    if (this.view) {
      let obj = this.view.objects[object.id]
      if (obj) {
        obj.setModel(model, true)
        obj.setStore(object)
        this.view.updateCulling([obj])
        return obj
      } else {
        if (object.shape === 'area') {
          switch (object.type) {
            case 'group': {
              obj = new Context.Diagram.Obj.Group(this.view, object.id)
              break
            }
            default: {
              obj = new Context.Diagram.Obj.Area(this.view, object.id)
              break
            }
          }
        } else {
          switch (object.type) {
            case 'actor': {
              obj = new Context.Diagram.Obj.Actor(this.view, object.id)
              break
            }
            case 'app': {
              obj = new Context.Diagram.Obj.App(this.view, object.id)
              break
            }
            case 'component': {
              obj = new Context.Diagram.Obj.Comp(this.view, object.id)
              break
            }
            case 'system': {
              obj = new Context.Diagram.Obj.System(this.view, object.id)
              break
            }
            case 'store': {
              obj = new Context.Diagram.Obj.Store(this.view, object.id)
              break
            }
            default: {
              throw new Error(`Failed to create ${object.type} object ${object.shape} shape`)
            }
          }
        }
        obj.setView(this.view)
        obj.setModel(model, true)
        obj.setStore(object)
        obj.setSelected(this.selectedObjectIds?.includes(object.id) ?? false)
        this.view.objects[obj.id] = obj
        this.view.updateCulling([obj])
      }
      return obj
    } else {
      throw new Error('Could not find view')
    }
  }

  initObject (objectId: string) {
    if (this.view) {
      const obj = this.view.objects[objectId]
      if (obj.initialized) {
        obj.resetModel()
        obj.resetStore()
      } else {
        obj.init()
      }
      this.view.scene.addChild(obj as DisplayObject)
      this.view.updateCulling([obj])
      return obj
    } else {
      throw new Error('Could not find view')
    }
  }

  removeObject (objectId: string) {
    if (this.view) {
      const obj = this.view.objects[objectId]
      if (obj) {
        this.view.scene.removeChild(obj as DisplayObject)
        delete this.view.objects[objectId]
      }
    }
  }

  upsertConnection ({ connection, model }: LandscapeSetupPreview['connections'][0]) {
    if (this.view) {
      let con = this.view.connections[connection.id]
      if (con) {
        con.setModel(model, true)
        this.view.updateCulling([con])
      } else {
        con = new Context.Diagram.Obj.Connection(this.view, connection.id)
        con.setView(this.view)
        con.setModel(model, true)
        con.setStore(connection)
        con.setSelected(this.selectedConnectionIds?.includes(connection.id) ?? false)
        this.view.connections[con.id] = con
        this.view.updateCulling([con])
      }
      return con
    } else {
      throw new Error('Could not find view')
    }
  }

  initConnection (connectionId: string) {
    if (this.view) {
      const con = this.view.connections[connectionId]
      if (con.initialized) {
        con.resetModel()
        con.resetStore()
      } else {
        con.init()
      }
      this.view.scene.addChild(con as DisplayObject)
      this.view.updateCulling([con])
      return con
    } else {
      throw new Error('Could not find view')
    }
  }

  removeConnection (connectionId: string) {
    if (this.view) {
      const obj = this.view.connections[connectionId]
      if (obj) {
        this.view.scene.removeChild(obj as DisplayObject)
        delete this.view.connections[connectionId]
      }
    }
  }
}
