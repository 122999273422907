import { CatalogTechnology } from '@icepanel/platform-api-client'

export interface LandscapeSetupFormStepOption {
  name: string
  technologies?: CatalogTechnology[]
  presetTechnologyIds?: string[]
}

export interface LandscapeSetupData {
  actor?: LandscapeSetupFormStepOption
  app?: LandscapeSetupFormStepOption
  api?: LandscapeSetupFormStepOption
  store?: LandscapeSetupFormStepOption

  appConnectionName?: string
  apiReturn?: string
}

interface AttributedString {
  text: string
  classes?: string[]
  link?: string
}

export interface LandscapeSetupFormStep {
  headline?: string
  title?: (AttributedString | string)[] | string
  subtitle?: (AttributedString | string)[] | string
  options?: LandscapeSetupFormStepOption[]
  cta?: {
    label: string
    icon?: string
  }
  type: 'selection' | 'text' | 'tech-choices' | 'cta'
}

export const onboardingSteps: LandscapeSetupFormStep[] = [
  {
    cta: {
      icon: '$fas-plus',
      label: 'Someone else'
    },
    headline: 'Let\'s kick off your model with a simple user flow you know well',
    options: [{ name: 'Existing customer' }, { name: 'New customer' }, { name: 'Employee' }],
    subtitle: 'An actor is someone who get\'s value from your solution',
    title: ['Which ', { classes: ['tw-font-bold'], text: 'Person' }, ' shall we start with?'],
    type: 'selection'
  },
  {
    cta: {
      icon: '$fas-plus',
      label: 'Something else'
    },
    options: [
      { name: 'Web app', presetTechnologyIds: ['9ZYWgwsvAwKsjKK8lNgg', 'WhqEU0pTC5sQxRAqPbvT', 'jATXWrTXRtgKXaSApBcR', 'unq9eBvtMu9rTTSQqqUP'] },
      { name: 'Mobile app', presetTechnologyIds: ['c7xPiAvyCwxNDi9D2X3K', 'LWtr8moPO6ajEsDjmRKp'] }
    ],
    subtitle: [{ classes: ['tw-underline'], text: 'An App at level 2' }, ' is a deployable/runnable service'],
    title: ['What ', { classes: ['tw-font-bold'], text: 'App' }, ' do ', { classes: ['tw-font-bold'], text: '{actorName}\'s' }, ' interact with?'],
    type: 'selection'
  },
  {
    subtitle: 'Tech choices can be overlayed dynamically in diagrams and filter your model',
    title: ['What is your ', { classes: ['tw-font-bold'], text: '{appName}' }, ' built with?'],
    type: 'tech-choices'
  },
  {
    cta: {
      icon: '$fas-plus',
      label: 'Something else'
    },
    options: [{ name: 'Purchase product/service' }, { name: 'Generate report' }, { name: 'Create account' }],
    subtitle: 'Connections should always be labeled',
    title: ['How do ', { classes: ['tw-font-bold'], text: '{actorName}\'s' }, ' use your ', { classes: ['tw-font-bold'], text: '{appName}' }, '?'],
    type: 'selection'
  },
  {
    subtitle: 'Give descriptive names your audience can understand, try avoiding acronyms',
    title: ['What is your ', { classes: ['tw-font-bold'], text: 'API' }, ' called?'],
    type: 'text'
  },
  {
    subtitle: 'Tech choices can be overlayed dynamically in diagrams and filter your model',
    title: ['What is your ', { classes: ['tw-font-bold'], text: '{apiName}' }, ' built with?'],
    type: 'tech-choices'
  },
  {
    cta: {
      icon: '$fas-plus',
      label: 'Something else'
    },
    options: [
      { name: 'Database', presetTechnologyIds: ['vz0i3MfydmkkvneQfC3q', '5Ss49TOHf4RPR69Ci7El'] },
      { name: 'Bucket', presetTechnologyIds: ['xbadj8VP1MqwHFF2I1Hm'] },
      { name: 'Cache', presetTechnologyIds: ['Yj8CPPXztMD4nMBi04y8', 'TXH0PmtJOXG1lSRsZq1p'] }
    ],
    subtitle: 'Add stores at level 2 to show your storage solutions',
    title: ['Where does your ', { classes: ['tw-font-bold'], text: '{apiName}' }, ' store data?'],
    type: 'selection'
  },
  {
    subtitle: 'Tech choices can be overlayed dynamically in diagrams and filter your model',
    title: ['What is your ', { classes: ['tw-font-bold'], text: '{storeName}' }, ' built with?'],
    type: 'tech-choices'
  },
  {
    cta: {
      icon: '$fas-plus',
      label: 'Something else'
    },
    options: [{ name: 'Success message' }, { name: 'Error message' }, { name: 'Timeout message' }],
    subtitle: 'Show response messages in a flow to avoid many bi-directional arrows',
    title: ['What does your ', { classes: ['tw-font-bold'], text: '{appName}' }, ' return to ', { classes: ['tw-font-bold'], text: '{actorName}' }, '?'],
    type: 'selection'
  },
  {
    cta: {
      icon: '$fas-play',
      label: 'Play Flow'
    },
    subtitle: 'This is a great first step into creating your model.',
    title: 'Cool! Your first flow is created!',
    type: 'cta'
  }
]
