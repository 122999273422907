
import { CatalogTechnology, ModelObjectType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { LandscapeSetupData, LandscapeSetupFormStep, onboardingSteps } from '@/modules/landscape/helpers/setup-form'

import ButtonOptions from './button-options.vue'
import CTAButton from './cta-button.vue'
import TechnologyList from './technology-list.vue'
import TextOption from './text-option.vue'

@Component({
  components: {
    ButtonOptions,
    CTAButton,
    TechnologyList,
    TextOption
  },
  name: 'LandscapeSetupForm'
})
export default class extends Vue {
  @Prop() loading!: boolean
  @Prop() currentStepIndex!: number
  @Prop() data!: LandscapeSetupData

  onboardingSteps = onboardingSteps

  get currentStep (): LandscapeSetupFormStep {
    return onboardingSteps[this.currentStepIndex]
  }

  get currentStepText (): string {
    switch (this.currentStepIndex) {
      case 4: return this.data.api?.name ?? ''
      default: return ''
    }
  }

  get technologyList (): CatalogTechnology[] {
    switch (this.currentStepIndex) {
      case 2: return this.data.app?.technologies ?? []
      case 5: return this.data.api?.technologies ?? []
      case 7: return this.data.store?.technologies ?? []
      default: return []
    }
  }

  get getPresetTechnologyIds (): string[] {
    switch (this.currentStepIndex) {
      case 2: return this.data.app?.presetTechnologyIds ?? []
      case 5: return ['lvUyvYYpLK6PQCoaAeKR', 'uaSXZEJX1B4YItfRZgEy', 'iuOnsR2022MCP8AMt20L', 'unq9eBvtMu9rTTSQqqUP']
      case 7: return this.data.store?.presetTechnologyIds ?? []
      default: return []
    }
  }

  get currentStepModelObjectType (): ModelObjectType | undefined {
    switch (this.currentStepIndex) {
      case 2: return 'app'
      case 5: return 'app'
      case 7: return 'store'
      default: return undefined
    }
  }

  get errorMessage (): string {
    const { app, actor, api, store } = this.data
    return (
      this.checkNames(app?.name, actor?.name, 'App name cannot be the same as Actor name') ||
      this.checkNames(api?.name, app?.name, 'API name cannot be the same as App name') ||
      this.checkNames(api?.name, actor?.name, 'API name cannot be the same as Actor name') ||
      this.checkNames(store?.name, app?.name, 'Store name cannot be the same as App name') ||
      this.checkNames(store?.name, actor?.name, 'Store name cannot be the same as Actor name') ||
      this.checkNames(store?.name, api?.name, 'Store name cannot be the same as API name') ||
      ''
    )
  }

  checkNames (name1?: string, name2?: string, errorMessage?: string) {
    if (name1 && name2 && name1 === name2) {
      return errorMessage
    }
    return ''
  }

  handleNext () {
    if (!this.errorMessage) {
      this.$emit('next')
    }
  }

  replaceTextPlaceholders (textSegment: string | { text: string }) {
    if (typeof textSegment === 'string') {
      return textSegment
    } else {
      return textSegment.text
        .replaceAll('{appName}', this.data.app?.name ?? 'App')
        .replaceAll('{apiName}', this.data.api?.name ?? 'API')
        .replaceAll('{actorName}', this.data.actor?.name ?? 'Actor')
        .replaceAll('{storeName}', this.data.store?.name ?? 'Store')
    }
  }

  openLink (link: string) {
    window.open(link, '_blank')?.focus()
  }
}
