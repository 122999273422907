
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { LandscapeSetupFormStep, onboardingSteps } from '@/modules/landscape/helpers/setup-form'

@Component({
  name: 'LandscapeSetupButtonOptions'
})
export default class extends Vue {
  @Prop() currentStepIndex!: number
  @Prop() errors!: string

  @Ref() optionRefs!: HTMLButtonElement[]

  nameFocused = false
  addingCustomOption = false
  customText = ''

  get currentStep (): LandscapeSetupFormStep {
    return onboardingSteps[this.currentStepIndex]
  }

  mounted () {
    this.optionRefs?.[0]?.focus()
  }

  customOption () {
    this.addingCustomOption = true
  }

  resetCustomOption () {
    this.customText = ''
    this.addingCustomOption = false
  }
}
