
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'LandscapeSetupTextOption'
})
export default class extends Vue {
  @Prop() placeholder!: string
  @Prop() initialText!: string
  @Prop() errors!: string

  focused = false
  text = this.initialText
}
