
import { CatalogTechnology, ModelObjectType } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { popularTechChoices } from '@/modules/catalog/helpers/popular-tech-choices'
import { CatalogModule } from '@/modules/catalog/store'
import { ModelModule } from '@/modules/model/store'

@Component({
  name: 'LandscapeSetupTechnologyList'
})
export default class extends Vue {
  catalogModule = getModule(CatalogModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly heading!: string
  @Prop() readonly searchPlaceholder!: string
  @Prop() readonly technologies!: CatalogTechnology[]
  @Prop() readonly presetTechnologyIds?: string[]
  @Prop({ default: undefined }) readonly modelObjectType?: ModelObjectType

  loading = false

  searchTerm = ''
  searchTermModel = ''
  searchFocused = false
  selectedTechnologies: CatalogTechnology[] = []

  async created () {
    this.selectedTechnologies = this.technologies

    this.searchTerm = ''
    this.searchTermModel = ''

    if (!this.catalogModule.technologiesFetched) {
      this.loading = true
      await this.catalogModule.catalogTechnologiesList({})
      this.loading = false
    }

    this.setPredefinedTechnologies()
  }

  setPredefinedTechnologies () {
    if (!this.selectedTechnologies.length) {
      this.presetTechnologyIds?.forEach(id => {
        const technology = this.catalogModule.technologies[id]
        if (technology) {
          this.addTechnology(technology)
        }
      })
    }
  }

  get sortedTechnologies () {
    const popularTechChoiceIds: string[] = this.modelObjectType ? popularTechChoices[this.modelObjectType] : []

    const selectedTechnologies = this.selectedTechnologies
      .sort((a, b) => {
        const aSelectedCount: number | undefined = this.selectedTechnologies.filter(t => t.id === a.id).length
        const bSelectedCount: number | undefined = this.selectedTechnologies.filter(t => t.id === b.id).length

        if (aSelectedCount === bSelectedCount) {
          return a.name.localeCompare(b.name)
        } else if (aSelectedCount !== undefined && bSelectedCount !== undefined) {
          return bSelectedCount - aSelectedCount
        } else if (aSelectedCount !== undefined && bSelectedCount === undefined) {
          return -1
        } else if (aSelectedCount === undefined && bSelectedCount !== undefined) {
          return 1
        } else {
          return a.name.localeCompare(b.name)
        }
      })
    const selectedTechnologyIds = selectedTechnologies.map(o => o.id)

    return [
      ...selectedTechnologies,
      ...popularTechChoiceIds
        .map(o => this.catalogModule.technologies[o])
        .filter(o => o && !selectedTechnologyIds.includes(o.id)),
      ...Object
        .values(this.catalogModule.technologies)
        .filter((o) => {
          if (popularTechChoiceIds.includes(o.id)) {
            return false
          }
          if (selectedTechnologyIds.includes(o.id)) {
            return false
          }
          switch (this.modelObjectType) {
            case 'system':
              return o.isSystem
            case 'app':
              return o.isApp
            case 'store':
              return o.isStore
            default:
              return false
          }
        })
    ]
  }

  get filteredTechnologiesFuzzy () {
    return new Fuse(this.sortedTechnologies, {
      keys: [
        'name',
        'nameShort',
        'provider'
      ],
      threshold: 0.3
    })
  }

  get filteredTechnologies () {
    return this.searchTerm ? this.filteredTechnologiesFuzzy.search(this.searchTerm).map(o => o.item) : this.sortedTechnologies
  }

  get mappedTechnologies () {
    return this.filteredTechnologies.map(o => {
      const assigned = this.selectedTechnologies.some(t => t.id === o.id)
      return {
        assigned,
        background: false,
        click: () => {
          if (!assigned) {
            this.addTechnology(o)
          } else {
            this.removeTechnology(o)
          }
        },
        icon: iconUrlForTheme(o),
        technology: o
      }
    })
  }

  addTechnology (technology: CatalogTechnology) {
    if (this.selectedTechnologies.find(o => o.id === technology.id)) {
      return
    }
    this.selectedTechnologies.push(technology)
    this.$emit('selected', this.selectedTechnologies)
  }

  removeTechnology (technology: CatalogTechnology) {
    this.selectedTechnologies = this.selectedTechnologies.filter(o => o.id !== technology.id)
    this.$emit('selected', this.selectedTechnologies)
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }
}
